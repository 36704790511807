import React, { Component } from "react";
import "./Stylesheets/App.css";
import "./Stylesheets/App.mobile.css";
//import "./Stylesheets/css_theme.css";
import MainForm from "./Components/MainForm";
import Amplify from 'aws-amplify'
import config from './aws-exports'
Amplify.configure(config)

class App extends Component {
  render() {
    return <MainForm />;
  }
}

export default App;
