import React, { Component } from "react";

//Other.jsx
import { addLocaleData } from "react-intl";
import frLocaleData from "react-intl/locale-data/fr";
addLocaleData(frLocaleData);

class AppInfo extends Component {
  render() {
    const { values } = this.props;
    let validation = this.props.AppInfoValidator;
    if (values.currentStep !== 1) {
      return null;
    }

    return (
      <div className="form-group">
        <div className="form_description">
          <h2>
            <b>Genesis Application Questionnaire</b>
          </h2>
          <p>
            As a result of the university-medical center split, VUIT and VUMC 
            IT are implementing several major transition projects. Currently, 
            the university and medical center share a legacy authentication 
            environment and all that comes with it. Genesis will allow the 
            university to implement a new VU-only authentication environment.
          </p>
          <p>
            Part of the Genesis program is gathering information about 
            applications to enable us to successfully migrate to the 
            University-only authentication environment with minimal disruption.
          </p>
          <p>
            We have populated the form with information available to VUIT and 
            are seeking your assistance in completing the information in the 
            form. Note: each form is for a single application.
          </p>
          <br />
          <div className="instructions">
            <h3 className="header">How To Complete This Form</h3>
            <ol>
              <li className="ordered_list">
                Enter your name and email address into the <b>Completed By</b> field.
              </li>
              <li className="ordered_list">
                Click <b>NEXT</b> or <b>PREVIOUS</b> (bottom of each page) to
                navigate to pages.
              </li>
              <li className="ordered_list">
                Complete all fields that you can provide information for.
                <ul>
                  <li className="unordered_list">
                    Some fields already contain information. Please verify for
                    accuracy and make corrections as needed.
                  </li>
                  <li className="unordered_list">
                    If you do not know the information for a given field, please
                    enter <i>Unknown.</i>
                  </li>
                </ul>
              </li>
              <li className="ordered_list">
                Click <b>SAVE AS DRAFT</b> at any time to save progress.
              </li>
              <li className="ordered_list">
                Click <b>SUBMIT</b> to confirm submission.<br /> <br />
              </li>
            </ol> 
            <ul className="notes">
              <i>Note:</i>
              <li className="unordered_list">
                You should be able to complete the questionnaire in approximately
                 10 minutes. However, you can press <b>SAVE AS DRAFT</b> to save
                your changes and then return later to finish.
              </li>
            </ul>
            <br />
          </div>
          <br />
        </div>
        <ul id="AppInfo.jsx">
          <li id="App_Info_Header" className="section_break">
            <h3>APPLICATION INFORMATION</h3>
            <br />
          </li>
          <li id="Last_Updated_By" className={validation.Last_Updated_By.isInvalid ? "error" : "Last_Updated_By"}>
            <label className="description">
              Completed By:
              <span id="required_3" className="required">
                *
              </span>
            </label>
            <div>
              <input
                className="element text medium"
                type="text"
                placeholder="Enter your name"
                required
                value={values.Last_Updated_By}
                onChange={this.props.handleChange("Last_Updated_By")}
              />
            </div>
            <p className="error">
              {validation.Last_Updated_By.message}
            </p>
          </li>
          <li id="Last_Updated_Email" className={validation.Last_Updated_Email.isInvalid ? "error" : "email"}>
            <div>
              <input
                type="email"
                maxLength="255"
                placeholder="Enter your email"
                className="element text medium"
                required
                value={values.Last_Updated_Email}
                onChange={this.props.handleChange("Last_Updated_Email")}
              />
            </div>
            <p className="error">
              {validation.Last_Updated_Email.message}
            </p>
          </li>
          <li id="Application_Name">
            <label className="description">
              APPLICATION NAME:
            </label>
            <div>
              <input
                className="element text medium"
                type="text"
                value={values.Application_Name}
                readOnly
              />
            </div>
          </li>
          <li id="Primary_Technical_Resource">
            <label className="description">Technical Resource Name:</label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Primary_Technical_Resource}
                onChange={this.props.handleChange("Primary_Technical_Resource")}
              />
            </div>
          </li>
          <li id="Primary_Relationship_Manager">
            <label className="description">Relationship Manager Name:</label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Primary_Relationship_Manager}
                onChange={this.props.handleChange("Primary_Relationship_Manager")}
              />
            </div>
          </li>
          <li id="Business_Unit">
            <label className="description">Department/Business Unit Name:</label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Business_Unit}
                onChange={this.props.handleChange("Business_Unit")}
              />
            </div>
          </li>
          <li id="Business_Unit_Email" className="email">
            <label className="description">Department/Business Unit Email Address:</label>
            <div>
              <input
                type="email"
                maxLength="255"
                className="element text medium"
                value={values.Business_Unit_Email}
                onChange={this.props.handleChange("Business_Unit_Email")}
              />
            </div>
          </li>
          <li id="Business_Unit_Manager_Director">
            <label className="description">
              Department/Business Unit Manager/Director Name:
            </label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Business_Unit_Manager_Director}
                onChange={this.props.handleChange(
                  "Business_Unit_Manager_Director"
                )}
              />
            </div>
          </li>
          <li id="Business_Unit_Manager_Director_Email" className="email">
            <label className="description">
              Department/Business Unit Manager/Director Email Address:
            </label>
            <div>
              <input
                type="email"
                maxLength="255"
                className="element text medium"
                value={values.Business_Unit_Manager_Director_Email}
                onChange={this.props.handleChange(
                  "Business_Unit_Manager_Director_Email"
                )}
              />
            </div>
          </li>
          <li id="Business_Unit_Manager_Director_Phone" className="phone">
            <label className="description">
              Department/Business Unit Manager/Director Phone Number:
            </label>
            <div>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                className="element text medium"
                placeholder="###-###-####"
                value={values.Business_Unit_Manager_Director_Phone}
                onChange={this.props.handleChange(
                  "Business_Unit_Manager_Director_Phone"
                )}
              />
            </div>
          </li>
          <li id="Vendor_Name">
            <label className="description">Application Vendor Name:</label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Vendor_Name}
                onChange={this.props.handleChange("Vendor_Name")}
              />
            </div>
          </li>
          <li id="Vendor_Sales_Name">
            <label className="description">
              Application Vendor Salesperson Name:
            </label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Vendor_Sales_Name}
                onChange={this.props.handleChange("Vendor_Sales_Name")}
              />
            </div>
          </li>
          <li id="Vendor_Sales_Email" className="email">
            <label className="description">
              Application Vendor Salesperson Email:
            </label>
            <div>
              <input
                type="email"
                maxLength="255"
                className="element text medium"
                value={values.Vendor_Sales_Email}
                onChange={this.props.handleChange("Vendor_Sales_Email")}
              />
            </div>
          </li>
          <li id="Vendor_Sales_Phone_Number" className="phone">
            <label className="description">
              Application Vendor Salesperson Phone Number:
            </label>
            <div>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                className="element text medium"
                placeholder="###-###-####"
                value={values.Vendor_Sales_Phone_Number}
                onChange={this.props.handleChange(
                  "Vendor_Sales_Phone_Number"
                )}
              />
            </div>
          </li>
          <li id="Vendor_Support_Email" className="email">
            <label className="description">
              Application Vendor Technical Support Email:
            </label>
            <div>
              <input
                type="email"
                maxLength="255"
                className="element text medium"
                value={values.Vendor_Support_Email}
                onChange={this.props.handleChange(
                  "Vendor_Support_Email"
                )}
              />
            </div>
          </li>
          <li id="Vendor_Support_Phone_Number" className="phone">
            <label className="description">
              Application Vendor Technical Support Phone Number:
            </label>
            <div>
              <input
                type="tel"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                className="element text medium"
                placeholder="###-###-####"
                value={values.Vendor_Support_Phone_Number}
                onChange={this.props.handleChange(
                  "Vendor_Support_Phone_Number"
                )}
              />
            </div>
          </li>
          <li id="Vendor_Additional_Info">
            <label className="description">
            Application Vendor Additional Information (List website, support portal):
            </label>
            <div>
              <textarea
                rows="8"
                cols="90"
                className="element textarea medium"
                value={values.Vendor_Additional_Info}
                onChange={this.props.handleChange("Vendor_Additional_Info")}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default AppInfo;
