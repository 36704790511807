import React, { Component } from "react";

class Confirmation extends Component {
  
  CreateQuestionnaireDetails() {
    let {
      values: {
        // AppInfo.jsx Variables
        Last_Updated_By,
        Last_Updated_Email,
        Application_Name,
        Primary_Technical_Resource,
        Primary_Relationship_Manager,
        Business_Unit,
        Business_Unit_Email,
        Business_Unit_Manager_Director,
        Business_Unit_Manager_Director_Email,
        Business_Unit_Manager_Director_Phone,
        Vendor_Name,
        Vendor_Sales_Name,
        Vendor_Sales_Email,
        Vendor_Sales_Phone_Number,
        Vendor_Support_Email,
        Vendor_Support_Phone_Number,
        Vendor_Additional_Info,
  
        // Application Owner Section
        // AppSpecifics.jsx Variables
        Application_Purpose,
        Still_In_Use,
        Frequency_of_Use,
        Departments_Using_Application,
        Number_of_Application_Users,
        Preferred_Migration_Quarter,
        Sensitive_Data,
  
        // AppLocAndSysInfoAO.jsx Variables
        Work_Impact,
        Application_URL,
        Internet_Connected_Devices,
        Internet_Connected_Devices_Email_FileTransfer,
        Technical_Resources_Needed,
        Type_of_App,
        Current_Support,
        Expiration_Date,
        MFA,
        //Auth_Type,
        //Other_Auth_Type,
        ResourceID_Accounts,
        //SSO,
        //Other_SSO,
        Clustered_OR_Load_balanced,
        Other_Environments,
        Application_IP,
        AppServers,
        Upstream_Dependencies,
        Downstream_Dependencies,
        
        // Other.jsx Variables
        Other_Info,
  
        // Confirmation.jsx Variables
        Auth_TypeString,
        SSOString
        }
      } = this.props;

    let Questionnaire_Details = this.props.Questionnaire_Details;

    var AppServer = AppServers.map(function (AppServer) {
      return (
        <tr className="eachRow">
          <td>
            {AppServer.HostName}
          </td>
          <td>
            {AppServer.Host_IP}
          </td>
          <td>
            {AppServer.OS_Name}
          </td>
          <td>
            {AppServer.OS_Version}
          </td>
          <td>
            {AppServer.Physical_Or_Virtual}
          </td>
          <td>
            {AppServer.Data_Center_Location}
          </td>
          <td>
            {AppServer.Ports_In_Use}
          </td>
        </tr>
      );
    });

    Questionnaire_Details = (
      <div className="form-group confirmation_page">
      <ul>
        <li className="section_break">
          <h3> Confirm Your Details</h3>
          <p>
            Click Confirm if the following details have been correctly entered
          </p>
        </li>
        <li className="section_break AppInfo">
          <h3>APPLICATION INFORMATION</h3>
          <ul id="AppInfo.jsx" className="section_questions_and_answers">
            <li id="Last_Updated_By">
              <b>Completed By: </b> {Last_Updated_By}
              <br/>
              <b>Email: </b> {Last_Updated_Email}
            </li>
            <li id="Application_Name">
              <b>APPLICATION NAME: </b> {Application_Name}
            </li>
            <li id="Primary_Technical_Resource">
              <b>Technical Resource Name: </b> {Primary_Technical_Resource} 
            </li>
            <li id="Primary_Relationship_Manager">
              <b>Relationship Manager Name: </b> {Primary_Relationship_Manager} 
            </li>
            <li id="Business_Unit">
              <b>Department/Business Unit Name:</b> {Business_Unit}
            </li>
            <li id="Business_Unit_Email">
              <b>Department/Business Unit Email Address: </b> {Business_Unit_Email}
            </li>
            <li id="Business_Unit_Manager_Director">
              <b>Department/Business Unit Manager/Director Name: </b> {Business_Unit_Manager_Director}
            </li>
            <li id="Business_Unit_Manager_Director_Email">
              <b>Department/Business Unit Manager/Director Email Address: </b> {Business_Unit_Manager_Director_Email}
            </li>
            <li id="Business_Unit_Manager_Director_Phone">
              <b>Department/Business Unit Manager/Director Phone Number: </b> {Business_Unit_Manager_Director_Phone}
            </li>
            <li id="Vendor_Name">
              <b>Application Vendor Name: </b> {Vendor_Name}
            </li>
            <li id="Vendor_Sales_Name">
              <b>Application Vendor Salesperson Name: </b> {Vendor_Sales_Name}
            </li>
            <li id="Vendor_Sales_Email">
              <b>Application Vendor Salesperson Email: </b> {Vendor_Sales_Email}
            </li>
            <li id="Vendor_Sales_Phone_Number">
              <b>
                Application Vendor Salesperson Phone Number:</b> {Vendor_Sales_Phone_Number}
            </li>
            <li id="Vendor_Support_Email">
              <b>Application Vendor Technical Support Email: </b> {Vendor_Support_Email}
            </li>
            <li id="Vendor_Support_Phone_Number">
                <b>Application Vendor Technical Support Phone Number: </b> {Vendor_Support_Phone_Number}
            </li>
            <li id="Vendor_Additional_Info">
              <b>Application Vendor Additional Information: </b> {Vendor_Additional_Info}
            </li>
          </ul>
        </li>

        <li className="section_break AppSpecifics">
          <h3>APPLICATION SPECIFICS</h3>
          <ul id="AppSpecifics.jsx" className="section_questions_and_answers">
            <li id="Application_Purpose">
              <b>Purpose of application (Briefly explain what the application does) </b> {Application_Purpose}
            </li>
            <li id="Still_In_Use">
              <b>Is the application still in use? (Including limited or rare use, such as for reporting.) </b> {Still_In_Use}
            </li>
            <li id="Frequency_of_Use">
              <b>Frequency of use? </b> {Frequency_of_Use}        
            </li>
            <li id="Departments_Using_Application">
              <b>List the department(s) that uses the application: </b> {Departments_Using_Application}
            </li>
            <li id="Number_of_Application_Users">
              <b>How many users? </b> {Number_of_Application_Users}       
            </li>
            <li id="Preferred_Migration_Quarter">
              <b>Preferred Migration Fiscal Quarter: </b> {Preferred_Migration_Quarter}
            </li>
            <li id="Sensitive_Data">
              <b>If the application contains sensitive data, please indicate the type. </b>{Sensitive_Data}
            </li>
          </ul>
        </li>

        <li className="section_break AppLocAndSysInfo">
          <h3>APPLICATION LOCATION & SYSTEM INFORMATION</h3>
          <ul id="AppLocAndSysInfo.jsx" className="section_questions_and_answers">
            <li id="Work_Impact">
              <b>Impacted Populations: </b> {Work_Impact}
            </li>
            <li id="Application_URL">
              <b>What is the application URL(s)? </b> {Application_URL}
            </li>
            <li id="Internet_Connected_Devices">
              <b>Devices connected to the internet: </b> {Internet_Connected_Devices}
            </li>
            <li id="Internet_Connected_Devices_Email_FileTransfer">
              <b>Devices that send automatic emails or automatic file transfers: </b> {Internet_Connected_Devices_Email_FileTransfer}
            </li>
            <li id="Technical_Resources_Needed">
              <b>Technical Resources Needed: </b> {Technical_Resources_Needed}
            </li>
            <li id="Type_of_App">
              <b>System Details - TYPE OF APPLICATION: </b> {Type_of_App}
            </li>
            <li id="Current_Support">
              <b>System Details - CURRENT SUPPORT: </b> {Current_Support}
            </li>
            <li id="Expiration_Date">
              <b>If supported, what is the expiration date? Does support renew automatically or requires manual approval? </b> {Expiration_Date}
            </li>
            <li id="MFA">
              <b>Does the application use multi-factor authentication? </b> {MFA}
            </li>
            <li id="Auth_Type">
              <b>What type of authentication does the application use? </b> {Auth_TypeString}
            </li>
            <li id="ResourceID_Accounts">
              <b>What resource/administrative accounts are used to run the application service? </b> {ResourceID_Accounts}
            </li>
            <li id="SSO">
              <b> If the application uses single sign on (SSO), please indicate the type: </b> {SSOString}
            </li>
            <li id="Clustered_OR_Load_balanced">
              <b>Is the application clustered and/or load-balanced? </b> {Clustered_OR_Load_balanced}
            </li>
            <li id="Other_Environments">
              <b>Does the application have other environments besides Production? </b> {Other_Environments}
            </li>
            <li id="Application_IP">
              <b>What is the application IP address(es)? </b> {Application_IP}
            </li>
            <li id="Server_Table">
              <b>Server Information:</b>
              <div>
                  <table>
                      <thead>
                          <tr>
                          <th>Host Name</th>
                          <th>Host IP Address</th>
                          <th>Operating System Name</th>
                          <th>Operating System Version</th>
                          <th>Infrastructure Type</th>
                          <th>Data Center Location</th>
                          <th>Application Ports in Use</th>
                          </tr>
                      </thead>
                      <tbody>{AppServer}</tbody>
                  </table>
              </div>
            </li>
            <li id="Upstream_Dependencies">
              <b>What applications and/or services is the application dependent upon in order to run correctly? </b> {Upstream_Dependencies}
            </li>
            <li id="Downstream_Dependencies">
              <b>What applications and/or services are dependent upon this application for it to run correctly?</b> {Downstream_Dependencies}
            </li>
          </ul>  
        </li>
        
        <li className="section_break Other">
          <h3> OTHER INFORMATION (Optional)</h3>
          <ul id="Other.jsx" className="section_questions_and_answers">
            <li>
              <b>Please list/explain any other pertinent information: </b>
              {Other_Info}
            </li>
          </ul>
        </li>
      </ul>
    </div>
    );
    return Questionnaire_Details;
  };

  render() {
    const {
      values: {
        currentStep,
      }
    } = this.props;
    
    if (currentStep !== 5) {
      return null;
    }
    
    return (
      <div>
        {this.CreateQuestionnaireDetails()}
      </div>
    );
  }
}
export default Confirmation;