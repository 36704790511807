import React, { Component } from "react";



class Other extends Component {
  render() {
    const { values } = this.props;
    if (values.currentStep !== 4) {
      return null;
    }
    return (
      <div className="form-group">
        <ul>
          <li id="Other_Info_Header" className="section_break">
            <h3> OTHER INFORMATION (Optional)</h3>
          </li>
          <li id="Other_Info">
            <label className="description">
              Please list/explain any other pertinent information:
            </label>
            <div>
              <textarea
                rows="8"
                cols="90"
                className="element textarea medium"
                value={values.Other_Info}
                onChange={this.props.handleChange("Other_Info")}
              />
            </div>
          </li>
          <li id="Thank_You" className="section_break">
            <h3>THANK YOU!</h3>
            <p />
          </li>
        </ul>  
      </div>
    );
  }
}

export default Other;
