import React, { Component } from "react";

class AppLocAndSysInfo extends Component {
  render() {
    const { values } = this.props;
    if (values.currentStep !== 3) {
      return null;
    }

        //AppLocAndSysIfoTR.jsx
        let handleProductTable = this.props.handleProductTable;
        let handleAddEvent = this.props.handleAddEvent;
        let handleRowDel = this.props.handleRowDel;
        // Other option for Type of Authentication used
        let Other_Auth_TypeInput = "";
        if (values.Auth_Type === "Other, please specify") {
          Other_Auth_TypeInput = (
            <div>
              <input
                type="text"
                className="element text other"
                placeholder="Other, please specify"
                value={values.Other_Auth_Type}
                onChange={this.props.handleChange("Other_Auth_Type")}
              />
            </div>
          );
        } else {
          Other_Auth_TypeInput = "";
          window.myData.appdata[0].Other_Auth_Type = "";
        }
    
        // Other option for SSO Type used
        let Other_SSOInput = "";
        if (values.SSO === "Other, please specify") {
          Other_SSOInput = (
            <div>
              <input
                type="text"
                className="element text other"
                placeholder="Other, please specify"
                value={values.Other_SSO}
                onChange={this.props.handleChange("Other_SSO")}
              />
            </div>
          );
        } else {
          Other_SSOInput = "";
          window.myData.appdata[0].Other_SSO = "";
        }

    return (
      <div className="form-group">
        <ul>
          <li id="App_Location_and_System_Info_Header" className="section_break">
            <h3>APPLICATION LOCATION & SYSTEM INFORMATION</h3>
          </li>
          <li id="Work_Impact">
            <label className="description">Impacted Populations: </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Work_Impact}
                onChange={this.props.handleChange("Work_Impact")}
              />
            </div>
            <p className="guidelines" id="guide_20">
              <small>
                The options are Faculty, Staff, Student - Undergraduate, 
                Student - Graduate, Student - Professional, and/or VIP Customer. If you 
                do not see the option you need, then list it. Please list 
                all that apply, and use a comma to separate each option.
              </small>
            </p>
          </li>
          <li id="Application_URL" className="URL">
            <label className="description">
              What is the application URL(s)? (I.e., the URL(s) used to access the 
              application at VU in all environments.) 
            </label>
            <div>
              <input
                type="url"
                className="element text large"
                value={values.Application_URL}
                onChange={this.props.handleChange("Application_URL")}
              />
            </div>
            <p className="guidelines" id="guide_30">
              <small>
                List all that apply and seperate them using commas. 
                Example: oraclecloud.vanderbilt.edu
              </small>
            </p>
          </li>
          <li id="Internet_Connected_Devices">
            <label className="description">
              List any devices that connect to the Internet
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Internet_Connected_Devices}
                onChange={this.props.handleChange("Internet_Connected_Devices")}
              />
            </div>
          </li>
          <li id="Internet_Connected_Devices_Email_FileTransfer">
            <label className="description">
              List any devices that send automatic emails or automatic file transfers
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Internet_Connected_Devices_Email_FileTransfer}
                onChange={this.props.handleChange("Internet_Connected_Devices_Email_FileTransfer")}
              />
            </div>
          </li>
          <li id="Technical_Resources_Needed" >
            <label className="description">Technical Resources Needed: </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Technical_Resources_Needed}
                onChange={this.props.handleChange("Technical_Resources_Needed")}
              />
            </div>
            <p className="guidelines" id="guide_35">
              <small>
                The options are Customer, External/vendor, VUIT app dev, 
                VUMC app dev, VUIT infrastructure, VUMC infrastructure, VUIT 
                security, and/or VUMC security. If you 
                do not see the option you need, then list it. Please list 
                all that apply, and use a comma to separate each option.
              </small>
            </p>
          </li>
          <li id="Type_of_App" className="radio_buttons two_columns">
            <div>
              <fieldset>
                <label className="description">
                  System Details - TYPE OF APPLICATION:
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Vendor"
                    checked={values.Type_of_App === "Vendor"}
                    onChange={this.props.handleChange("Type_of_App")}
                  />
                  <label className="choice">Vendor</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Custom"
                    checked={values.Type_of_App === "Custom"}
                    onChange={this.props.handleChange("Type_of_App")}
                  />
                  <label className="choice">Custom</label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="Current_Support" className="radio_buttons two_columns">
            <div>
              <fieldset>
                <label className="description">
                  System Details - CURRENT SUPPORT:
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Supported"
                    checked={values.Current_Support === "Supported"}
                    onChange={this.props.handleChange("Current_Support")}
                  />
                  <label className="choice">Supported</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Unsupported"
                    checked={values.Current_Support === "Unsupported"}
                    onChange={this.props.handleChange("Current_Support")}
                  />
                  <label className="choice">Unsupported</label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="Expiration_Date">
            <label className="description">
            If supported, what is the expiration date? Does support renew automatically or requires manual approval?
            </label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Expiration_Date}
                onChange={this.props.handleChange("Expiration_Date")}
              />
            </div>
            <p className="guidelines" id="Expiration_Date_guide">
              <small>
                Date of support expiration and/or type of renewal
              </small>
            </p>
          </li>
          <li id="MFA" className="radio_buttons two_columns">
            <div>
              <fieldset>
                <label className="description">
                  Does the application use multi-factor authentication?
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Yes"
                    checked={values.MFA === "Yes"}
                    onChange={this.props.handleChange("MFA")}
                  />
                  <label className="choice">Yes</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="No"
                    checked={values.MFA === "No"}
                    onChange={this.props.handleChange("MFA")}
                  />
                  <label className="choice">No</label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="Auth_Type" className="radio_buttons three_columns">
            <div>
              <fieldset>
                <label className="description">
                  What type of authentication does the application use?
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="LDAP"
                    checked={values.Auth_Type === "LDAP"}
                    onChange={this.props.handleChange("Auth_Type")}
                  />
                  <label className="choice">LDAP</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="AD"
                    checked={values.Auth_Type === "AD"}
                    onChange={this.props.handleChange("Auth_Type")}
                  />
                  <label className="choice">AD</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="RADIUS"
                    checked={values.Auth_Type === "RADIUS"}
                    onChange={this.props.handleChange("Auth_Type")}
                  />
                  <label className="choice">RADIUS</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Other, please specify"
                    onChange={this.props.handleChange("Auth_Type")}
                    checked={values.Auth_Type === "Other, please specify"}
                  />
                  <label className="choice">
                    Other, please specify: {Other_Auth_TypeInput}
                  </label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="ResourceID_Accounts">
            <label className="description">
              What resource/administrative accounts are used to run the application service?
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.ResourceID_Accounts}
                onChange={this.props.handleChange("ResourceID_Accounts")}
              />
            </div>
            <p className="guidelines" id="guide_44">
              <small>
                Use a comma and a space to seperate each host name.
              </small>
            </p>
          </li>
          <li id="SSO" className="radio_buttons three_columns">
            <div>
              <fieldset>
                <label className="description">
                  If the application uses single sign on (SSO), please indicate
                  the type:
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Ping"
                    checked={values.SSO === "Ping"}
                    onChange={this.props.handleChange("SSO")}
                  />
                  <label className="choice">Ping</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="ADFS"
                    checked={values.SSO === "ADFS"}
                    onChange={this.props.handleChange("SSO")}
                  />
                  <label className="choice">ADFS</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="CAS"
                    checked={values.SSO === "CAS"}
                    onChange={this.props.handleChange("SSO")}
                  />
                  <label className="choice">CAS</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Other, please specify"
                    onChange={this.props.handleChange("SSO")}
                    checked={values.SSO === "Other, please specify"}
                  />
                  <label className="choice">
                    Other, please specify: {Other_SSOInput}
                  </label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="Clustered_OR_Load_balanced">
            <label className="description">
              Is the application clustered and/or load-balanced?
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Clustered_OR_Load_balanced}
                onChange={this.props.handleChange("Clustered_OR_Load_balanced")}
              />
            </div>
            <p className="guidelines" id="guide_46">
              <small>
                The options are Clustered and/or Load Balanced.
                Please list all that apply, and use a comma to separate each option.
              </small>
            </p>
          </li>
          <li id="Other_Environments">
            <label className="description">
            Does the application have other environments besides Production?
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Other_Environments}
                onChange={this.props.handleChange("Other_Environments")}
              />
            </div>
            <p className="guidelines" id="guide_48">
              <small>
                List all that apply: DEV, QA, TEST, UAT, TRAIN environments
                Use commas to separate each option listed.
              </small>
            </p>
          </li>
          <li id="Application_IP">
            <label className="description">
              What is the application IP address(es)?
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Application_IP}
                onChange={this.props.handleChange("Application_IP")}
              />
            </div>
            <p className="guidelines" id="Application_IP_guide">
              <small>
                List all that apply; it could be a range.
                Use commas to separate each option listed.
              </small>
            </p>
          </li>
          <li id="Server_Table">
            <label className="description">Server Information:</label>
            <div>
              <AppServerTable
                classname="element table"
                onProductTableUpdate={handleProductTable.bind(this)}
                onRowAdd={handleAddEvent.bind(this)}
                onRowDel={handleRowDel.bind(this)}
                AppServers={values.AppServers}
              />
            </div>
          </li>
          <li id="Upstream_Dependencies">
            <label className="description">
              What applications and/or services is the application dependent
              upon in order to run correctly?
            </label>
            <div>
              <textarea
                rows="8"
                cols="90"
                className="element textarea large"
                value={values.Upstream_Dependencies}
                onChange={this.props.handleChange("Upstream_Dependencies")}
              />
            </div>
          </li>
          <li id="Downstream_Dependencies">
            <label className="description">
              What applications and/or services are dependent upon this
              application for it to run correctly?
            </label>
            <div>
              <textarea
                rows="8"
                cols="90"
                className="element textarea large"
                value={values.Downstream_Dependencies}
                onChange={this.props.handleChange("Downstream_Dependencies")}
              />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

class AppServerTable extends React.Component {
  render() {
    var onProductTableUpdate = this.props.onProductTableUpdate;
    var rowDel = this.props.onRowDel;
    var AppServer = this.props.AppServers.map(function (AppServer) {
      return (
        <AppServerRow
          onProductTableUpdate={onProductTableUpdate}
          AppServer={AppServer}
          onDelEvent={rowDel.bind(this)}
          key={AppServer.AppServer_ID}
        />
      );
    });
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>Host Name</th>
              <th>Host IP Address</th>
              <th>Operating System Name</th>
              <th>Operating System Version</th>
              <th>Infrastructure Type</th>
              <th>Data Center Location</th>
              <th>Application Ports in Use</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{AppServer}</tbody>
        </table>
        <button
          type="button"
          onClick={this.props.onRowAdd}
          className="button_text buttons"
        >Add</button>
      </div>
    );
  }
}

class AppServerRow extends React.Component {
  onDelEvent() {
    this.props.onDelEvent(this.props.AppServer);
  }
  render() {
    return (
      <tr className="eachRow">
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "HostName",
            value: this.props.AppServer.HostName,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "Host_IP",
            value: this.props.AppServer.Host_IP,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "OS_Name",
            value: this.props.AppServer.OS_Name,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "OS_Version",
            value: this.props.AppServer.OS_Version,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "Physical_Or_Virtual",
            value: this.props.AppServer.Physical_Or_Virtual,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "Data_Center_Location",
            value: this.props.AppServer.Data_Center_Location,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <EditableCell
          onProductTableUpdate={this.props.onProductTableUpdate}
          cellData={{
            type: "Ports_In_Use",
            value: this.props.AppServer.Ports_In_Use,
            id: this.props.AppServer.AppServer_ID
          }}
        />
        <td className="del-cell">
          <button
            type="button"
            onClick={this.onDelEvent.bind(this)}
            className="button_text buttons"
          >
            Delete
          </button>
        </td>
      </tr>
    );
  }
}

class EditableCell extends React.Component {
  render() {
    return (
      <td>
        <input
          className="element table"
          type="text"
          name={this.props.cellData.type}
          id={this.props.cellData.id}
          value={this.props.cellData.value}
          onChange={this.props.onProductTableUpdate}
        />
      </td>
    );
  }
}

export default AppLocAndSysInfo;
