import React, { Component } from "react";

class AppSpecifics extends Component {
  render() {
    const { values } = this.props;
    if (values.currentStep !== 2) {
      return null;
    }

    return (
      <div className="form-group">
        <ul id="AppSpecifics.jsx">
          <li id="App_Specifics_Header" className="section_break">
            <h3>APPLICATION SPECIFICS</h3>
          </li>
          <li id="Application_Purpose">
            <label className="description">
              Purpose of application (Briefly explain what the application does)
            </label>
            <div>
              <textarea
                rows="8"
                cols="90"
                className="element textarea medium"
                value={values.Application_Purpose}
                onChange={this.props.handleChange("Application_Purpose")}
              />
            </div>
            <p className="guidelines" id="Application_Purpose_guide">
              <small>
                Briefly, explain what the application does, what business need
                it performs, etc.
              </small>
            </p>
          </li>
          <li id="Still_In_Use" className="radio_buttons two_columns">
            <div>
              <fieldset>
                <label className="description">
                  Is the application still in use? (Including limited or rare
                  use, such as for reporting.)
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Yes"
                    checked={values.Still_In_Use === "Yes"}
                    onChange={this.props.handleChange("Still_In_Use")}
                  />
                  <label className="choice">Yes</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="No"
                    checked={values.Still_In_Use === "No"}
                    onChange={this.props.handleChange("Still_In_Use")}
                  />
                  <label className="choice">No</label>
                </span>
              </fieldset>
            </div>
          </li>
          <li id="Frequency_of_Use" className="radio_buttons three_columns">
            <div>
              <fieldset>
                <label className="description">
                  Frequency of use?
                </label>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Daily"
                    checked={values.Frequency_of_Use === "Daily"}
                    onChange={this.props.handleChange("Frequency_of_Use")}
                  />
                  <label className="choice">Daily</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Weekly"
                    checked={values.Frequency_of_Use === "Weekly"}
                    onChange={this.props.handleChange("Frequency_of_Use")}
                  />
                  <label className="choice">Weekly</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Monthly"
                    checked={values.Frequency_of_Use === "Monthly"}
                    onChange={this.props.handleChange("Frequency_of_Use")}
                  />
                  <label className="choice">Monthly</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Quarterly"
                    checked={values.Frequency_of_Use === "Quarterly"}
                    onChange={this.props.handleChange("Frequency_of_Use")}
                  />
                  <label className="choice">Quarterly</label>
                </span>
                <span>
                  <input
                    type="radio"
                    className="element radio"
                    value="Annually"
                    checked={values.Frequency_of_Use === "Annually"}
                    onChange={this.props.handleChange("Frequency_of_Use")}
                  />
                  <label className="choice">Annually</label>
                </span>
              </fieldset>
            </div>
            <p className="guidelines" id="Frequency_of_Use_guide">
              <small>
                Choose one: Daily, Weekly, Monthly, Quarterly, Annually (e.g. biweekly would be Weekly)
              </small>
            </p>
          </li>
          <li id="Departments_Using_Application">
            <label className="description">
              List the department(s) that uses the application:
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Departments_Using_Application}
                onChange={this.props.handleChange("Departments_Using_Application")}
              />
            </div>
            <p className="guidelines" id="guide_21">
              <small>
                Use a comma and a space to seperate each department.
              </small>
            </p>
          </li>
          <li id="Number_of_Application_Users">
            <label className="description">How many users?</label>
            <div>
              <input
                type="text"
                className="element text medium"
                value={values.Number_of_Application_Users}
                onChange={this.props.handleChange("Number_of_Application_Users")}
              />
            </div>
            <p className="guidelines" id="guide_23">
              <small>
                The estimated number of people who use the application.
              </small>
            </p>
          </li>
          <li id="Preferred_Migration_Quarter">
            <label className="description">Preferred Migration Fiscal Quarter:</label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Preferred_Migration_Quarter}
                onChange={this.props.handleChange("Preferred_Migration_Quarter")}
              />
            </div>
             <p className="guidelines" id="guide_24">
              <small>
                List all that apply: 1st, 2nd, 3rd and/or 4th; specify 2020 or 2021 if applicable since we have 18 months.
                Use commas to separate each option listed.
              </small>
            </p>
          </li>
          <li id="Sensitive_Data">
            <label className="description">
              If the application contains sensitive data, please indicate the
              type.
            </label>
            <div>
              <input
                type="text"
                className="element text large"
                value={values.Sensitive_Data}
                onChange={this.props.handleChange("Sensitive_Data")}
              />
            </div>
            <p className="guidelines" id="guide_25">
              <small>
                List all that apply: PII, HIPAA, SOX, PHI, PCI and/or FERPA or specify if other.
                Use commas to separate each option listed.
              </small>
            </p>
          </li>
        </ul>
      </div>
    );
  }
}

export default AppSpecifics;
