import React, { Component } from "react";

// import form pages
import AppInfo from "./AppInfo";
import AppSpecifics from "./AppSpecifics";
import AppLocAndSysInfo from "./AppLocAndSysInfo";
import Other from "./Other";
import Confirmation from "./Confirmation";

// import FormValidator
import FormValidator from "./FormValidator";

class TestApp extends Component {
  constructor() {
    super();
    
    this.AppInfoValidator = new FormValidator([
      {
        field: "Last_Updated_By",
        method: "isEmpty",
        validWhen: false,
        message: "Your name is required."
      },
      {
        field: "Last_Updated_Email",
        method: "isEmpty",
        validWhen: false,
        message: "Your email is required."
      },
      {
        field: "Last_Updated_Email",
        method: "isEmail",
        validWhen: true,
        message: "That is not a valid email."
      }
    ]);
    
    this.state = {
      currentStep: 1,
      // AppInfo.jsx Variables
      Last_Updated_By: "",
      Last_Updated_Email: "",
      Application_Name: "",
      Primary_Technical_Resource: "",
      Primary_Relationship_Manager: "",
      Business_Unit: "",
      Business_Unit_Email: "",
      Business_Unit_Manager_Director: "",
      Business_Unit_Manager_Director_Email: "",
      Business_Unit_Manager_Director_Phone: "",
      Vendor_Name: "",
      Vendor_Sales_Name: "",
      Vendor_Sales_Email: "",
      Vendor_Sales_Phone_Number: "",
      Vendor_Support_Email: "",
      Vendor_Support_Phone_Number: "",
      Vendor_Additional_Info: "",
      AppInfoValidation: this.AppInfoValidator.valid(),

      // Application Owner Section
      // AppSpecifics.jsx Variables
      Application_Purpose: "",
      Still_In_Use: "",
      Frequency_of_Use: "",
      Departments_Using_Application: "",
      Number_of_Application_Users: "0",
      Preferred_Migration_Quarter: "",
      Sensitive_Data: "",

      // AppLocAndSysInfo.jsx Variables
      Work_Impact: "",
      Application_URL: "",
      Internet_Connected_Devices: "",
      Internet_Connected_Devices_Email_FileTransfer: "",
      Technical_Resources_Needed: "",
      Type_of_App: "",
      Current_Support: "",
      Expiration_Date: "",
      MFA: "",
      Auth_Type: "",
      Other_Auth_Type: "",
      ResourceID_Accounts: "",
      SSO: "",
      Other_SSO: "",
      Clustered_OR_Load_balanced: "",
      Other_Environments: "",
      Application_IP: "",
      /* AppServers: [
          {
           AppServer_ID: id,
           HostName: "",
           Host_IP: "",
           OS_Name: "",
           OS_Version: "",
           Physical_Or_Virtual: "",
           Data_Center_Location: "",
           Ports_In_Use: ""
          }
       ], */
      AppServers: [],
      Upstream_Dependencies: "",
      Downstream_Dependencies: "",
      
      // Other.jsx Variables
      Other_Info: "",
      
      // Confirmation.jsx Variables
      Auth_TypeString: "",
      SSOString: "",
      Questionnaire_Details: <p></p>
    };
  }
  componentDidMount = () => {
    //var myData = {};
    const axios = require('axios');
    //const appNumber = this.props.location.query.a;
    //var apiurl = this.props.query;
    console.log(window.location.href);
    var url = window.location.href;
    var param = url.split("?");
    var querystrings = param[1];
    var querystring = querystrings.split("&");
    var appnumber = querystring[1];
    var password = querystring[0];
    var pageparam = querystring[2];
    var pagenumberstring = pageparam.split("=");
    var pagenumber = parseInt(pagenumberstring[1]);
    window.password = password;
    window.appnumber = appnumber;
    axios.get('https://66hc0auwv5.execute-api.us-east-1.amazonaws.com/prod/webForm?' + password + '&' + appnumber)
      .then(res => {
      // handle success
        console.log(res.data);
        //myData.data = response.data;
        //myData.Application_Name = response.data.appdata[0].Application_Name;
        const myData = res.data;
        window.myData = myData;
        const appdatabase = myData.appdata[0];
        this.setState(appdatabase);
        //Clear name and email box
        this.setState({
          Last_Updated_By: "",
          Last_Updated_Email: ""
        });
        this.setState({
          AppServers: myData.appserverdata
        });
        this.setState({currentStep: pagenumber})
        console.log(this.state);
    });
  }
  handleSubmit = event => {
    //event.preventDefault();
    let currentStep = this.state.currentStep;
    if (currentStep === 5) {
      // Send an email to client
      alert("Questionnaire Submitted");
    } else {
      alert("Draft Saved");
    }
    const axios = require('axios');
    //axios.push("")
    axios.post('https://66hc0auwv5.execute-api.us-east-1.amazonaws.com/prod/webForm?' + window.password + '&' + window.appnumber, window.myData)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });  
      //add Update to Database here
  };

  // Change handler for strings
  handleChange = input => event => {
    this.setState({ [input]: event.target.value });
    window.myData.appdata[0][input] = event.target.value;

  };


  /*
   * the functions for our buttons
   */
  previousButton() {
    let currentStep = this.state.currentStep;
    if (currentStep !== 1) {
      return (
        <button
          className="button_text buttons"
          type="button"
          onClick={this.prevStep}
        >
          Previous
        </button>
      );
    }
    return null;
  }

  prevStep = () => {
    let currentStep = this.state.currentStep;
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    //this.handleSubmit();
    this.setState({
      currentStep: currentStep
    });
  };

  nextButton() {
    let currentStep = this.state.currentStep;

    if (currentStep < 4) {
      return (
        <button
          className="button_text buttons"
          type="button"
          onClick={this.nextStep}
        >
          Next
        </button>
      );
    } else if (currentStep === 4) {
      return (
        <button
          className="button_text buttons"
          type="button"
          onClick={() => {
            this.nextStep();
            this.OtherToString();
          }}
        >
          Confirm Application Information
        </button>
      );
    }
    return null;
  }

  nextStep = () => {
    let currentStep = this.state.currentStep;
    //currentStep = currentStep >= 7 ? 8 : currentStep + 1;
    // this.handleSubmit();
    // const AppInfoValidation = this.AppInfoValidator.validate(this.state);
    // this.setState({
    //   AppInfoValidation: AppInfoValidation
    // });
    // this.setState({
    //   currentStep: currentStep
    // });
    switch (currentStep) {
      case 1:
        const AppInfoValidation = this.AppInfoValidator.validate(this.state);
        this.setState({
          AppInfoValidation: AppInfoValidation
        });
        if (AppInfoValidation.isValid) {
          currentStep = currentStep >= 4 ? 5 : currentStep + 1;
          this.setState({
            currentStep: currentStep
          });
        }
        break;
      default:
        currentStep = currentStep >= 4 ? 5 : currentStep + 1;
        this.setState({
          currentStep: currentStep
        });
    }
  };

  OtherToString = () => {
    // Type of Authentication
    let Auth_TypeString = this.state.Auth_TypeString;
    let Auth_Type = this.state.Auth_Type;
    let Other_Auth_Type = this.state.Other_Auth_Type;
    if (Auth_Type === "Other, please specify") {
      Auth_TypeString = Other_Auth_Type;
    } else {
      Auth_TypeString = Auth_Type;
    }

    // SSO Type
    let SSOString = this.state.SSOString;
    let SSO = this.state.SSO;
    let Other_SSO = this.state.Other_SSO;
    if (SSO === "Other, please specify") {
      SSOString = Other_SSO;
    } else {
      SSOString = SSO;
    }

    this.setState({
      Auth_TypeString: Auth_TypeString,
      SSOString: SSOString
    });
  };

  submitButton() {
    let currentStep = this.state.currentStep;

    if ( currentStep < 4) {
      return (
        <button
          className="button_text buttons"
          type="button"
          onClick={this.handleSubmit}
        >
          Save
        </button>
      );
    } else if (currentStep === 5) {
      return (
        <button
          className="button_text buttons"
          type="button"
          onClick={this.handleSubmit}
        >
          Submit Questionnaire
        </button>
      );
    }
    return null;
  }
  
  // Table Handlers for App Servers table
  handleRowDel(AppServer) {
    var index = this.state.AppServers.indexOf(AppServer);
    this.state.AppServers.splice(index, 1);
    window.myData.appserverdata = this.state.AppServers;
    this.setState(this.state.AppServers);
  }

  handleAddEvent(evt) {
    let AppServers = this.state.AppServers;
    var id = (Math.floor(Math.random() * 99999999));
    var AppServer = {
      //id: id,
      AppServer_ID: id,
      HostName: "",
      Host_IP: "",
      OS_Name: "",
      OS_Version: "",
      Physical_Or_Virtual: "",
      Data_Center_Location: "",
      Ports_In_Use: ""
    };

    this.setState({
      AppServers: AppServers.concat(AppServer)
    });
  }

  handleProductTable(evt) {
    var AppServers = this.state.AppServers.slice();
    var newAppServers = AppServers.map(function(AppServer) {
      for (var key in AppServer) {
        // eslint-disable-next-line
        if (key == evt.target.name && AppServer.AppServer_ID == evt.target.id) {
           AppServer[key] = evt.target.value;
        }
        
      }
      return AppServer;
    });
    window.myData.appserverdata = newAppServers;
    this.setState({ AppServers: newAppServers });
    //  console.log(this.state.AppServers);
  }

  render() {
    
    const {
      currentStep,
      // AppInfo.jsx Variables
      Last_Updated_By,
      Last_Updated_Email,
      Application_Name,
      Primary_Technical_Resource,
      Primary_Relationship_Manager,
      Business_Unit,
      Business_Unit_Email,
      Business_Unit_Manager_Director,
      Business_Unit_Manager_Director_Email,
      Business_Unit_Manager_Director_Phone,
      Vendor_Name,
      Vendor_Sales_Name,
      Vendor_Sales_Email,
      Vendor_Sales_Phone_Number,
      Vendor_Support_Email,
      Vendor_Support_Phone_Number,
      Vendor_Additional_Info,

      // Application Owner Section
      // AppSpecifics.jsx Variables
      Application_Purpose,
      Still_In_Use,
      Frequency_of_Use,
      Departments_Using_Application,
      Number_of_Application_Users,
      Preferred_Migration_Quarter,
      Sensitive_Data,

      // AppLocAndSysInfo.jsx Variables
      Work_Impact,
      Application_URL,
      Internet_Connected_Devices,
      Internet_Connected_Devices_Email_FileTransfer,
      Technical_Resources_Needed,
      Type_of_App,
      Current_Support,
      Expiration_Date,
      MFA,
      Auth_Type,
      Other_Auth_Type,
      ResourceID_Accounts,
      SSO,
      Other_SSO,
      Clustered_OR_Load_balanced,
      Other_Environments,
      Application_IP,

      AppServers,
      Upstream_Dependencies,
      Downstream_Dependencies,
      
      // Other.jsx Variables
      Other_Info,
      
      // Confirmation.jsx Variables
      Auth_TypeString,
      SSOString,
      Questionnaire_Details
    } = this.state;

    const values = {
      currentStep,
      // AppInfo.jsx Variables
      Last_Updated_By,
      Last_Updated_Email,
      Application_Name,
      Primary_Technical_Resource,
      Primary_Relationship_Manager,
      Business_Unit,
      Business_Unit_Email,
      Business_Unit_Manager_Director,
      Business_Unit_Manager_Director_Email,
      Business_Unit_Manager_Director_Phone,
      Vendor_Name,
      Vendor_Sales_Name,
      Vendor_Sales_Email,
      Vendor_Sales_Phone_Number,
      Vendor_Support_Email,
      Vendor_Support_Phone_Number,
      Vendor_Additional_Info,

      // Application Owner Section
      // AppSpecifics.jsx Variables
      Application_Purpose,
      Still_In_Use,
      Frequency_of_Use,
      Departments_Using_Application,
      Number_of_Application_Users,
      Preferred_Migration_Quarter,
      Sensitive_Data,

      // AppLocAndSysInfo.jsx Variables
      Work_Impact,
      Application_URL,
      Internet_Connected_Devices,
      Internet_Connected_Devices_Email_FileTransfer,
      Technical_Resources_Needed,
      Type_of_App,
      Current_Support,
      Expiration_Date,
      MFA,
      Auth_Type,
      Other_Auth_Type,
      ResourceID_Accounts,
      SSO,
      Other_SSO,
      Clustered_OR_Load_balanced,
      Other_Environments,
      Application_IP,
      AppServers,
      Upstream_Dependencies,
      Downstream_Dependencies,
      
      // Other.jsx Variables
      Other_Info,

      // Confirmation.jsx Variables
      Auth_TypeString,
      SSOString,
      Questionnaire_Details
    };

    return (
      <div id="main_body" className="">
        <div id="form_container" className="">
          <h1>
            {/* eslint-disable-next-line*/}
            <a>GENESIS APPLICATION QUESTIONNAIRE</a>
          </h1>
          <form
            id="form_1197358"
            className="appnitro top_label"
            method="post"
            data-highlightcolor="#FFF7C0"
            onSubmit={this.handleSubmit}
          >            
            {/*Page 1: AppInfo*/}
            <AppInfo
              handleChange={this.handleChange}
              AppInfoValidator={this.state.AppInfoValidation}
              values={values}
            />

            {/*Page 2: AppSpecifics*/}
            <AppSpecifics
              handleChange={this.handleChange}
              values={values}
            />

            {/*Page 3: AppLocAndSysInfo*/}
            <AppLocAndSysInfo
              handleChange={this.handleChange}
              handleProductTable={this.handleProductTable.bind(this)}
              handleAddEvent={this.handleAddEvent.bind(this)}
              handleRowDel={this.handleRowDel.bind(this)}
              values={values}
            />

            {/*Page 4: Other*/}
            <Other handleChange={this.handleChange} values={values} />

            {/*Page 5: Confirmation*/}
            <Confirmation handleChange={this.handleChange} values={values} />
            <br />
            {this.previousButton()}
            {this.nextButton()}
            {this.submitButton()}
            <label className="page_number">
              Page {values.currentStep} of 5
            </label>
          </form>
        </div>
      </div>
    );
  }
}

export default TestApp;
